import functions from "./functions.js";

$(function () {

    const $form = $('.form-contact');
    const form = $form[0];

    if (!form) {
        return;
    }

    $form.on('submit', e => {
        e.preventDefault();

        let params = {};
        $.each($form.serializeArray(), function (i, field) {
            params[field.name] = field.value;
        });

        params['language'] = functions.getLang();
        params['avio'] = $form.hasClass('form-avio');
        params['template'] = params['avio'] ? 'avio' : '';

        if(params['avio']) {
            const $dateFrom = $form.find('[name="dateFrom"]');
            const $dateTo = $form.find('[name="dateTo"]');
            const $guests = $form.find('[name="guests"]');
            $dateFrom.parents('.datepicker').toggleClass('focus', ! params['dateFrom'] && ! $dateFrom.val());
            $dateTo.parents('.datepicker').toggleClass('focus', ! params['dateTo'] && ! $dateTo.val());
            $guests.parents('#dropdown-guests').find('.input-guests').toggleClass('focus', ! params['guests'] && ! $guests.val());
    
            $dateFrom.on('change', e => {
                $(e.currentTarget).parents('.datepicker').removeClass('focus');
            });
            $dateTo.on('change', e => {
                $(e.currentTarget).parents('.datepicker').removeClass('focus');
            });
            $guests.on('change', e => {
                $(e.currentTarget).parents('#dropdown-guests').find('.input-guests').removeClass('focus');
            });
        }

        //$output.html('').addClass('d-none');
        if( ! params['avio'] || (params['dateFrom'] && params['dateTo'] && params['guests'])) {

            $('.contact-btn').addClass('d-none');
            $('.contact-spinner').removeClass('d-none');
            $('.contact-spinner').addClass('d-flex');
            
            grecaptcha.ready(function () {
                grecaptcha.execute('6LcaY1QqAAAAAFhJhUvsqpmlWpUgCaIc09Lhj_yW', { action: 'contact' }).then(function (token) {
                    params['gRecaptchaToken'] = token;
                    params = functions.getDataForUrl(params);
                    //console.log(params);
                    $.post('/services/contact/', params).then(response => {
                        //console.log(response);
                        response = JSON.parse(response);
    
                        $('#contact-modal').modal('show');
                        $(".response-output").html(response.data);
    
                        $('.contact-btn').removeClass('d-none');
                        $('.contact-spinner').removeClass('d-flex');
                        $('.contact-spinner').addClass('d-none');
    
                        setTimeout(() => { 
                            $('#contact-modal').modal('hide');
                            // details contact modal 
                            $('#contactModal').modal('hide');
                        }, 4000);
    
                        if (response.status == true) {
                            form.reset();
                        }
                    });
                });
            });
        }

    });

});