import functions from '../functions';
import pagination from '../components/pagination';
import listResults from '../components/listResults';

$(() => {
    list.init();
})

const list = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {},
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryId: null,
        sortBy: null, 
        objectName: null, 
    },
    mainFields: ['dateFrom', 'dateTo', 'destination', 'objectTypeId', 'persons', 'personsInObject', 'sortBy', '_page'],
    init() {

        this.$form = $('.search-form');
        this.form = this.$form[0];

        this.$filter = $('.search-filter');
        this.filter = this.$filter[0];  

        this.$listItems = $('.list-items');
        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;

        if(! this.form) {
            return;
        }

    },
    getParams(params, defaults = {}, filterParams = {}) {
        params = $.extend({}, defaults, filterParams, functions.getUrlData(true));
        this.splitCalendarDates(params.dates);
        // this.setDestination(params.destination);

        return params;
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    setDestination(destination) {

        if(destination) {
            const destinations = destination.split(',');

            let countryIds = [],
                regionIds = [],
                rivieraIds = [],
                placeIds = [];

            destinations.forEach(destination => {
                const dest = destination.split(':');
                
                this.params.countryId = dest[0] ? this.getDestinationIds(dest[0], countryIds) : null;
                this.params.regionId = dest[1] ? this.getDestinationIds(dest[1], regionIds) : null;
                this.params.rivieraId = dest[2] ? this.getDestinationIds(dest[2], rivieraIds) : null;
                this.params.placeId = dest[3] ? this.getDestinationIds(dest[3], placeIds) : null;
            });
        } else {
            this.params.countryId = null;
            this.params.regionId = null;
            this.params.rivieraId = null;
            this.params.placeId = null;
        }
    },
    getDestinationIds(destination, array) {
        if(destination && $.inArray(destination, array)) {
            array.push(destination);
        }
        return array.join();
    },
    find(params) {
        
        this.setLoadingMode();
        this.params = params;

        if (this.params.objectGroupId == 1)
        {
            if (this.params.dynamicPackage == 1) params.tagCategoryId = 1126;
            if (this.params.dynamicPackage == 2) params.tagCategoryId = 1131;
            if (this.params.dynamicPackage == 0 || ! this.params.dynamicPackage) params.tagCategoryId = 1125;
        }

        return $.get('/services/list/', params);

    },
    findDone(response) {
        response = JSON.parse(response);

        this.unsetLoadingMode();

        this.total = response.total;

        this.$listItems.html(response.html);

        functions.markFavorites();
        
        if (this.total != 0) {

            if($('.paginate')[0]) {
                $('.paginate').html(pagination({
                    currentPage: this.params._page,
                    total: this.total,
                    perPage: this.params.perPage,
                }));
            }
        }

        listResults.getResults(this.total, this.params._page, this.params.perPage);

        this.initComponents();

    },
    paginate(page) {
        if (page != this.params._page) {
            this.params._page = page;
        }

        functions.setUrlData(this.params, true);
    },
    setLoadingMode() {
        if(! this.loading) {
            this.loading = true;

            // form submit btn
            (this.$form).find('button').prop('disabled', true);

            if ( ! $('.spinner').length) {
                $('<div class="spinner d-none"><div class="spinner-border text-secondary" role="status" style="width: 4rem; height: 4rem;"><span class="sr-only">Loading...</span></div></div>').insertBefore('.list-items');
            }

            $('.spinner').removeClass('d-none');
            $('.spinner').addClass('d-flex justify-content-center');
            this.$listItems.addClass('d-none');
            $('.paginate').addClass('d-none');
        }
    },
    unsetLoadingMode() {
        if(this.loading) {
            this.loading = false;

            // form submit btn
            (this.$form).find('button').prop('disabled', false);

            $('.spinner').removeClass('d-flex justify-content-center');
            $('.spinner').addClass('d-none');
            this.$listItems.removeClass('d-none');
            $('.paginate').removeClass('d-none');

            //functions.scrollToElement(this.$listItems, 100);
        }
    },
    initComponents() {

        // register paginate        
        // $('[data-page]').on('click', e => {
        //     e.preventDefault();
        //     this.paginate(+$(e.currentTarget).attr('data-page'));
        // });

        // this.$sortBy = $('[name="sortBy"]');
        // this.sortBy = this.$sortBy[0] ? this.$sortBy[0] : null;

        // if(this.sortBy && this.params.sortBy) {
        //     this.$sortBy.each((index, elem) => {
        //         if(elem.type === 'checkbox') {
        //             elem.checked = elem.value === this.params.sortBy;
        //         } else if(elem.type === 'select-one') {
        //             $(elem).val(this.params.sortBy);
        //         }
        //     });
        // }

        // this.$sortBy.on('change', e => {

        //     let data = functions.getFormData($(e.currentTarget));
        //     data.sortBy = data.sortBy ? data.sortBy : '11:DESC';
        //     this.params = $.extend(this.params, this.getParams(), data);
        //     functions.setUrlData(this.params, true, true);
        // });

        if (this.$listItems.find('[data-bl]').length) {

            let objectIds = [];
            this.$listItems.find('[data-bl]').each((index, item) => {
                objectIds.push($(item).attr('data-bl'));
            });

            if (this.params.guests && this.params.dateFrom && this.params.dateTo) {
                $('[data-bl]').find('.bl-spinner').removeClass('d-none');
                $('[data-bl]').find('.unit-price-label').addClass('d-none');
                $('[data-bl]').find('.unit-price-total').addClass('d-none');
                $('[data-bl]').find('.bl-on-request').addClass('d-none');

                $.post('/services/mars_calculation_bros_list/', {
                    objectIds: objectIds,
                    guests: this.params.guests,
                    dateFrom: this.params.dateFrom,
                    dateTo: this.params.dateTo,
                }).always(response => {

                    response = response ? JSON.parse(response) : null;

                    if (response && response.status) {

                        objectIds.forEach(objectId => {
                            let $object = $('[data-bl="'+objectId+'"]');
                            let hasPrice = response.data[objectId] ? true : false;
                            $object.find('.unit-price-label').toggleClass('d-none', ! hasPrice);
                            $object.find('.unit-price-total').toggleClass('d-none', ! hasPrice);
                            $object.find('.bl-spinner').addClass('d-none');
                            $object.find('.bl-on-request').toggleClass('d-none', !! hasPrice);
                            hasPrice && $object.find('.bl-price').html(functions.formatMoney(response.data[objectId], 0, '.', ','));
                        });
                    } else {
                        objectIds.forEach(objectId => {
                            let $object = $('[data-bl="'+objectId+'"]');
                            $object.find('.unit-price-label').addClass('d-none');
                            $object.find('.unit-price-total').addClass('d-none');
                            $object.find('.bl-spinner').addClass('d-none');
                            $object.find('.bl-on-request').removeClass('d-none');
                        });
                    }
                })
            } else {
                $('[data-bl]').find('.bl-on-request').removeClass('d-none');
                $('[data-bl]').find('.bl-spinner').addClass('d-none');
                $('[data-bl]').find('.unit-price-label').addClass('d-none');
                $('[data-bl]').find('.unit-price-total').addClass('d-none');
            }

        }
    },
};

export {
    list,
};
